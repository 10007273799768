@use 'tailwindcss';
@plugin 'tailwindcss-animate';

@custom-variant dark (&:where(.dark, .dark *));
/* @custom-variant dark (&:is(.dark, .dark *)); */

@theme {
  --default-ring-width: 3px;
  --breakpoint-sm: 30rem;
  --breakpoint-md: 48rem;
  --breakpoint-lg: 62rem;
  --breakpoint-xl: 75rem;
  --breakpoint-2xl: 96rem;
  --breakpoint-el: 160rem;
  --radius: 0.5rem;
  --radius-lg: var(--radius);
  --radius-md: calc(var(--radius) - 2px);
  --radius-sm: calc(var(--radius) - 4px);
  --text-2xs: 0.5rem;
  --text-2xs--line-height: calc(1 / 0.5);
  --text-10xl: 10rem;
  --text-10xs--line-height: 1;
  --tracking-tighter: -0.05em;
  --tracking-tight: -0.025em;
  --tracking-normal: 0em;
  --tracking-wide: 0.25em;
  --tracking-wider: 0.5em;
  --tracking-widest: 2.5em;
  --default-font-family: var(--font-inter), var(--font-prompt);

  --color-background: hsl(var(--background));
  --color-background-deep: hsl(var(--background-deep));
  --color-foreground: hsl(var(--foreground));
  --color-card: hsl(var(--card));
  --color-card-foreground: hsl(var(--card-foreground));
  --color-popover: hsl(var(--popover));
  --color-popover-foreground: hsl(var(--popover-foreground));
  --color-primary: hsl(var(--primary));
  --color-primary-foreground: hsl(var(--primary-foreground));
  --color-secondary: hsl(var(--secondary));
  --color-secondary-foreground: hsl(var(--secondary-foreground));
  --color-tertiary: hsl(var(--tertiary));
  --color-tertiary-foreground: hsl(var(--tertiary-foreground));
  --color-quaternary: hsl(var(--quaternary));
  --color-quaternary-foreground: hsl(var(--quaternary-foreground));
  --color-muted: hsl(var(--muted));
  --color-muted-foreground: hsl(var(--muted-foreground));
  --color-accent: hsl(var(--accent));
  --color-accent-foreground: hsl(var(--accent-foreground));
  --color-destructive: hsl(var(--destructive));
  --color-destructive-foreground: hsl(var(--destructive-foreground));
  --color-border: hsl(var(--border));
  --color-input: hsl(var(--input));
  --color-ring: hsl(var(--ring));

  --animate-accordion-down: accordion-down 0.2s ease-out;
  --animate-accordion-up: accordion-up 0.2s ease-out;

  @keyframes accordion-down {
    from: {
      height: 0;
    }
    to: {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes accordion-up {
    from: {
      height: var(--radix-accordion-content-height);
    }
    to: {
      height: 0;
    }
  }
}

@layer base {
  :root {
    --background: 48 100% 98%;
    --background-deep: 217 100% 100%;
    --foreground: 217 0% 0%;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 0 100% 62%;
    --primary-foreground: 0 100% 62%;
    --secondary: 170 100% 43%;
    --secondary-foreground: 170 100% 43%;
    --tertiary: 256 100% 63%;
    --tertiary-foreground: 256 100% 63%;
    --quaternary: 0 100% 62%;
    --quaternary-foreground: 0 100% 62%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
  }

  .dark {
    --background: 0 0% 7%;
    --background-deep: 0 0% 0%;
    --foreground: 217 0% 100%;
    --background-start-rgb: 26, 26, 26;
    --background-end-rgb: 0, 0, 0;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 47 100% 50%;
    --primary-foreground: 47 100% 50%;
    --secondary: 170 96% 65%;
    --secondary-foreground: 170 96% 65%;
    --tertiary: 256 100% 63%;
    --tertiary-foreground: 256 100% 63%;
    --quaternary: 0 100% 62%;
    --quaternary-foreground: 0 100% 62%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }

  ::view-transition-old(root),
  ::view-transition-new(root) {
    animation: none !important;
  }

  ::view-transition-group(root) {
    z-index: auto !important;
  }

  ::view-transition-image-pair(root) {
    isolation: isolate;
    will-change: transform, opacity, clip-path;
    z-index: 1;
  }

  ::view-transition-new(root) {
    z-index: 1000;
    animation: none !important;
  }

  ::view-transition-old(root) {
    z-index: 1;
    animation: none !important;
  }

  button:not(:disabled),
  [role='button']:not(:disabled) {
    cursor: pointer;
  }

  * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    @apply border-border m-0 p-0;
  }
  *:focus {
    outline: 0 !important;
  }
  *:-moz-focusring {
    outline: 0 !important;
  }
  *::-webkit-scrollbar:horizontal {
    @apply hidden;
  }
  *::-webkit-scrollbar-track {
    @apply bg-background rounded-[0.75rem];
  }
  *::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }
  *::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    @apply bg-primary rounded-[0.75rem];
  }
  ::selection {
    @apply bg-primary text-background;
  }
  ::-moz-selection {
    @apply bg-primary text-background;
  }
  ::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  :-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  :-moz-ui-invalid {
    box-shadow: none;
  }

  body {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: subpixel-antialiased;
    font-family: var(--font-inter), var(--font-prompt);
    backface-visibility: hidden;
    text-rendering: optimizeLegibility;
    @apply bg-background-deep text-foreground text-base antialiased transition-colors duration-300;
  }
  a,
  a:hover {
    @apply no-underline;
  }
  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    text-decoration: none;
    @apply text-inherit no-underline;
  }
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  .h-svh {
    height: 100vh;
    height: 100svh;
  }
  .h-dvh {
    height: 100vh;
    height: 100dvh;
  }
  .h-lvh {
    height: 100vh;
    height: 100lvh;
  }
  .w-svw {
    width: 100vw;
    width: 100svw;
  }
  .w-dvw {
    width: 100vw;
    width: 100dvw;
  }
  .w-lvw {
    width: 100vw;
    width: 100lvw;
  }
  .NSB::-webkit-scrollbar {
    display: none;
  }
  .SSB::-webkit-scrollbar {
    width: 2px;
  }
}

@utility container-sm {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 2rem;
  max-width: 1024px;
}

@utility container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 2rem;
  max-width: 1400px;
}

@layer components {
  .anim-config {
    @apply transition-all duration-300 xl:hover:duration-200;
  }
  .anim-config-md {
    @apply transition-all duration-500 xl:hover:duration-300;
  }
  .anim-config-lg {
    @apply transition-all duration-700 xl:hover:duration-500;
  }
  .anim-scale {
    @apply hover:z-10 xl:hover:scale-150;
  }

  .anim-scale-105 {
    @apply hover:z-10 xl:hover:scale-105;
  }
  .anim-shadow-el {
    @apply xl:hover:shadow-[0_35px_60px_15px_rgba(0,0,0,1)];
  }
  .anim-scale-sm {
    @apply xl:hover:scale-110;
  }
  .anim-opacity-20 {
    @apply xl:opacity-20 xl:hover:opacity-100;
  }
  .anim-opacity-40 {
    @apply xl:opacity-40 xl:hover:opacity-100;
  }
  .anim-opacity-60 {
    @apply xl:opacity-60 xl:hover:opacity-100;
  }
  .anim-opacity-80 {
    @apply xl:opacity-80 xl:hover:opacity-100;
  }
  .anim-translate-10 {
    @apply xl:hover:-translate-x-2.5 xl:hover:-translate-y-2.5;
  }
  .anim-rotate-1 {
    @apply xl:hover:-rotate-1;
  }
  .anim-translate-4 {
    @apply xl:hover:-translate-x-1 xl:hover:-translate-y-1;
  }
  .-anim-translate-4 {
    @apply xl:hover:translate-x-1 xl:hover:translate-y-1;
  }
  .anim-saturate-0 {
    @apply xl:saturate-0 xl:hover:saturate-100;
  }

  .anim-text-underline {
    position: relative;
  }
  .anim-text-underline:before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 10px;
    width: calc(100% - 20px);
    height: 2px;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.5s transform;
    z-index: 90;
    @apply bg-primary hidden md:block;
  }
  .anim-text-underline:hover:before {
    opacity: 1;
    transition-duration: 250ms;
    cursor: none;
    transform: scaleX(1);
    transform-origin: left;
    transition: 0.5s transform;
  }
  .anim-text-underline-only-first-child > div:before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 10px;
    width: calc(100% - 20px);
    height: 2px;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.5s transform;
    z-index: 90;
    @apply bg-primary hidden md:block;
  }
  .anim-text-underline-only-first-child:hover > div:before {
    opacity: 1;
    transition-duration: 250ms;
    cursor: none;
    transform: scaleX(1);
    transform-origin: left;
    transition: 0.5s transform;
  }

  .text-stroke-foreground {
    -webkit-text-stroke: 1px hsl(var(--foreground));
    color: transparent;
  }

  .hide-last-child--parent > .isChild:last-child {
    @apply hidden;
  }

  .anim-text-stroke > h1:hover {
    -webkit-text-stroke: 2px #000;
    color: hsl(var(--foreground));
  }

  .text-outline-foreground {
    -webkit-text-stroke: 1px hsl(var(--foreground));
    @apply text-transparent;
  }

  .bullet-sm:before {
    content: '';
    @apply h-1 w-1 rounded-full;
  }

  @media (min-width: 768px) {
    .text-outline {
      -webkit-text-stroke: 2px #000;
    }
    .text-outline-dark {
      -webkit-text-stroke: 2px #fff;
    }
  }

  .border-40 {
    @apply border-foreground/40 border;
  }
  .border-40:focus,
  .border-40:hover,
  .border-40:active {
    @apply border-foreground/60;
  }
  .form-default input {
    @apply bg-foreground/5 dark:bg-foreground/10 px-2 py-1;
  }
  .form-default input::placeholder {
    @apply text-foreground/40;
  }
  .form-default input:-webkit-autofill,
  .form-default input:-webkit-autofill:focus,
  .form-default input:-webkit-autofill:hover,
  .form-default input:-webkit-autofill:active {
    /* transition: background-color 5000s ease-in-out 0s; */
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-text-fill-color: black !important;
    @apply bg-transparent;
  }
  .form-checkbox {
    @apply absolute z-10 opacity-0;
  }
  .form-checkbox + label {
    @apply relative cursor-pointer p-0;
  }
  .form-checkbox + label:before {
    content: '';
    @apply bg-foreground/20 mr-3 inline-block h-3 w-3 rounded-sm align-top;
  }
  .form-checkbox:hover + label:before {
    @apply bg-primary/60;
  }
  .form-checkbox:checked + label:before {
    @apply bg-primary;
  }
  .form-checkbox:disabled + label {
    @apply text-foreground/30 cursor-auto;
  }
  .form-checkbox:disabled + label:before {
    @apply bg-foreground/10;
  }
  .form-checkbox:checked + label:after {
    content: '';
    box-shadow:
      2px 0 0 hsl(var(--foreground)),
      4px 0 0 hsl(var(--foreground)),
      4px -2px 0 hsl(var(--foreground)),
      4px -4px 0 hsl(var(--foreground)),
      4px -6px 0 hsl(var(--foreground)),
      4px -8px 0 hsl(var(--foreground));
    @apply bg-foreground absolute left-[2px] top-1 h-1 w-1 rotate-45;
  }

  .navMenuItem:hover > div > p:first-child {
    opacity: 0.6;
  }
}
